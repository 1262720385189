/*
 * Copyright (C) 2018-2022 Garden Technologies, Inc. <info@garden.io>
 *
 * All rights reserved.
 */

import React from "react"
import { Button, LinkButton } from "../../components/button"
import { useClipboard } from "../../components/copy-to-clipboard"
import { Copy, LinkExternal, Trash } from "../../components/icons"
import { Input } from "../../components/input"
import { Page, type ProjectPageProps } from "../../components/page"
import { Text } from "../../components/text"
import { useEnv, useModals } from "../../contexts"
import { tokens } from "../../design-system"
import { settingsPageTabs } from "./shared"

export const ProjectSettings = ({ project }: ProjectPageProps) => {
  const copyProjectIDToClipboard = useClipboard(project.id, "Project ID copied to the clipboard.")
  const env = useEnv()
  const modals = useModals()

  return (
    <Page
      scope="project"
      name="settings"
      title="Settings"
      tabs={!env || !env.freeTierEnabled ? settingsPageTabs(project.id) : undefined}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: tokens.spacing[32],
          paddingTop: tokens.spacing[8],
          maxWidth: 450,
        }}
      >
        <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[8] }}>
          <Text weight="bold">Project ID</Text>
          <div css={{ display: "flex", gap: tokens.spacing[4] }}>
            <Input type="text" value={project.id} disabled />
            <Button Icon={Copy} title="Copy Project ID" onClick={copyProjectIDToClipboard} size="large" />
          </div>
        </div>

        <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[8] }}>
          <Text weight="bold">Repository</Text>
          <div css={{ display: "flex", gap: tokens.spacing[4] }}>
            <Input type="text" value={project.repositoryUrl} disabled />
            <LinkButton
              to={project.repositoryUrl}
              styles={{ display: "flex", alignItems: "stretch" }}
              Icon={LinkExternal}
              title="Go to repository"
              size="large"
              noWrapper
            />
          </div>
        </div>

        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: tokens.spacing[16],
            alignItems: "flex-start",
          }}
        >
          <div css={{ display: "flex", flexDirection: "column", gap: tokens.spacing[8] }}>
            <Text weight="bold">Delete project</Text>
            <Text>Delete the current project and all its data.</Text>
          </div>
          <Button
            Icon={Trash}
            variant="danger"
            size="large"
            onClick={() => modals.open({ type: "delete-project", projectId: project.id })}
          >
            Delete project
          </Button>
        </div>
      </div>
    </Page>
  )
}
